var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card card-icon mb-4"},[_c('div',{staticClass:"row no-gutters"},[_vm._m(0),_c('div',{staticClass:"col"},[_c('div',{staticClass:"card-body py-4"},[_c('h3',{staticClass:"card-title"},[_c('span',{class:_vm.isEditingResource ? 'text-danger' : ''},[_vm._v(_vm._s(_vm.titleForm))])]),_c('div',{staticClass:"card-text"},[_c('FormulateForm',{ref:"catalogoProyectosForm",on:{"submit":_vm.submit},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hasErrors = ref.hasErrors;
var isLoading = ref.isLoading;
return [_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"6"}},[_c('FormulateInput',{attrs:{"name":"periodo_fiscal","disabled":isLoading,"validation":"bail|required","error-behavior":"live","validation-messages":{
                    required: 'El periodo fiscal es requerido'
                  }},scopedSlots:_vm._u([{key:"element",fn:function(){return [_c('x-form-group',{attrs:{"title":"Periodo fiscal","required":""}},[_c('check-authorization',{attrs:{"requiresAuthorizations":['listar periodos fiscales'],"show-alert":false,"no-slots":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var authorized = ref.authorized;
                  var message = ref.message;
return [_c('span',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.top",modifiers:{"top":true}}],attrs:{"title":authorized ? '' : message}},[_c('x-select',{attrs:{"dispatchPath":"fiscalPeriodsModule/getFiscalPeriods","getterListPath":"fiscalPeriodsModule/getAllFiscalPeriods","formatListWith":['id', 'nombre'],"no-prepend":"","no-validate":"","disabled":!authorized},model:{value:(_vm.formValues.periodo_fiscal_id),callback:function ($$v) {_vm.$set(_vm.formValues, "periodo_fiscal_id", $$v)},expression:"formValues.periodo_fiscal_id"}})],1)]}}],null,true)})],1)]},proxy:true}],null,true),model:{value:(_vm.formValues.periodo_fiscal_id),callback:function ($$v) {_vm.$set(_vm.formValues, "periodo_fiscal_id", $$v)},expression:"formValues.periodo_fiscal_id"}})],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"6"}},[_c('x-form-group',{attrs:{"title":"Clave","required":""}},[_c('FormulateInput',{attrs:{"type":"text","name":"clave","disabled":isLoading,"placeholder":"Ingresa el número de clave","validation":"bail|required|max:100,length","validation-messages":{
                      required: 'La clave es requerida',
                      max: 'La longitud máxima es de 100 caracteres'
                    }},model:{value:(_vm.formValues.clave),callback:function ($$v) {_vm.$set(_vm.formValues, "clave", $$v)},expression:"formValues.clave"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"6"}},[_c('x-form-group',{attrs:{"title":"Nombre","required":""}},[_c('FormulateInput',{attrs:{"disabled":isLoading,"placeholder":"Ingresa el nombre del proyecto","validation":"bail|required|max:255,length","validation-messages":{
                      required: 'El nombre del proyecto es requerido',
                      max: 'La longitud máxima es de 255 caractéres'
                    }},model:{value:(_vm.formValues.nombre),callback:function ($$v) {_vm.$set(_vm.formValues, "nombre", $$v)},expression:"formValues.nombre"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"12","lg":"6"}},[_c('x-form-group',{attrs:{"title":"Estatus"}},[_c('div',{staticClass:"form-group"},[_c('b-form-select',{attrs:{"value":_vm.formValues.estatus.value,"options":[
                        { text: 'Activo', value: 1 },
                        { text: 'Inactivo', value: 0 }
                      ]},model:{value:(_vm.formValues.estatus),callback:function ($$v) {_vm.$set(_vm.formValues, "estatus", $$v)},expression:"formValues.estatus"}})],1)])],1)],1),_c('x-form-footer-buttons',{attrs:{"disabled":hasErrors || isLoading,"loading":isLoading,"isEditing":_vm.isEditing,"isCreating":!_vm.isEditing},on:{"on-cancel":_vm.resetForm,"on-click-create":_vm.submit,"on-click-update":_vm.submit}})]}}])})],1)])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-none\n          d-md-flex\n          col-auto\n          card-icon-aside\n          bg-primary\n          text-white"},[_c('i',{attrs:{"data-feather":"check-square"}})])}]

export { render, staticRenderFns }